type GeographicLocation = {
    address_components: Array<{
        long_name: string;
        short_name: string;
        types: Array<string>;
    }>;
};

function getObject(): GeographicLocation | null {
    const result = sessionStorage.getItem("geolocation-location") as string | null;

    return result ? JSON.parse(result) : null;
}

export function getData(key: string): string | null {
    const location = getObject();

    return (
        location?.address_components?.filter(function (item) {
            return item.types.includes(key);
        })[0]?.long_name ?? null
    );
}
