import { FlowClientEventChannel, PageEventSource } from "../Stores/page-events.ts";

interface PageEventContract {
    channel: string,
    event: string,
    data: GenericJson|null,
    source: PageEventSource,
}

export class PageEvent implements PageEventContract {
    private channelName: string = FlowClientEventChannel;
    private eventName: string;
    private eventData: GenericJson|null = null;
    private eventSource: PageEventSource = PageEventSource.FlowClient;

    public constructor(eventName: string, eventData?: GenericJson) {
        this.eventName = eventName;
        if (eventData)
            this.eventData = eventData;
    }

    public get channel(): string {
        return this.channelName;
    }

    public get event(): string {
        return this.eventName;
    }

    public get data(): GenericJson|null {
        return this.eventData && typeof(this.eventData) === 'object'
            ? JSON.parse(JSON.stringify(this.eventData))
            : null;
    }

    public get source(): PageEventSource {
        return this.eventSource;
    }

    public toJSON(): object {
        return {
            channel: this.channel,
            event: this.event,
            source: this.source,
            data: this.data,
        }
    }
}