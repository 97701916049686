<template>
    <div ref="fadeContainer"
         :class="currentRendererClasses"
         :style="currentRendererStyles"
    >
        <div ref="calculationContainer">
            <BlockRenderer
                v-for="block in nestedTree"
                :key="block.id"
                :id="block.id"
                :block="block"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import BlockRenderer from "../../WebRenderer/BlockRenderer.vue";
import {TransitionType, useFlowStore} from "../../../Stores/flow.ts";
import { onMounted, Ref, ref, useTemplateRef, watch } from "vue";
import {FadeTransitionService} from "../../../Services/Transition.ts";
import Block from "@flow-builder/core/src/Blocks/Core/Block.ts";
import calculatorEventService from "../../../Services/Analytics/CalculatorEventService.ts";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import {
    useBackgroundSlideClasses,
    useBackgroundStyles
} from "@flow-builder/core/src/Components/Renderer/Composables/useStylesTransformer.ts";
import {FlowStyle} from "@flow-builder/core/src/Flows/Flow.ts";
import { useConfigStore } from "../../../Stores/config.ts";

interface Props {
    nestedSlide: Slide,
}
const props = defineProps<Props>();

const flowStore = useFlowStore();
const config = useConfigStore();
const fadeContainer = useTemplateRef<HTMLElement>('fadeContainer');
const calculationContainer = useTemplateRef<HTMLElement>('calculationContainer');

const nestedTree: Ref<Block[]> = ref([]);
const currentRendererStyles: Ref<GenericJson> = ref({});
const currentRendererClasses: Ref<Object> = ref([]);
const transition = new FadeTransitionService();

const animateHeight: Ref<boolean> = ref(config.renderer.useHeightAnimation());
const oldSlideHeight: Ref<number|null> = ref(null);
const newSlideHeight: Ref<number|null> = ref(null);

const initialize = () => {
    nestedTree.value = props.nestedSlide.hierarchy;
    updateContainerStyles();
};

onMounted(() => initialize());

watch(() => props.nestedSlide, (newValue: Slide, oldValue: Slide) => {
    if (flowStore.transitionType !== TransitionType.TemplateToTemplate || !fadeContainer.value || !calculationContainer.value)
        return;

    flowStore.startRendererTransition();

    if (!oldValue) {
        nestedTree.value = newValue.hierarchy;
        updateContainerStyles();
        flowStore.updateGlobalStyleVariables();
        flowStore.fireSlideLoadedEvent(newValue);
        calculatorEventService.trackEvents(flowStore.sliderService.currentSlide?.name ?? '');
    } else {
        // For template-template, resetting scroll height during the fade-out seems to look better
        setTimeout(() => resetScrollHeight(), 50);

        transition.out(fadeContainer.value as HTMLElement).then(async () => {
            if (!fadeContainer.value || !calculationContainer.value)
                return;

            if (animateHeight.value) {
                newSlideHeight.value = null;
                oldSlideHeight.value = fadeContainer.value.getBoundingClientRect().height || null;

                if (oldSlideHeight.value) {
                    fadeContainer.value.style.height = `${oldSlideHeight.value}px`;

                    // hide calc container
                    calculationContainer.value.style.opacity = '0';

                    // mount & measure new nested slide, unhide container
                    nestedTree.value = newValue.hierarchy;
                    await new Promise(res => setTimeout(() => res(true), 5));
                    newSlideHeight.value = calculationContainer.value.getBoundingClientRect().height;

                    calculationContainer.value.style.position = '';
                    calculationContainer.value.style.opacity = '';
                }
            }
            else {
                nestedTree.value = newValue.hierarchy;
            }

            updateContainerStyles();
            flowStore.updateGlobalStyleVariables();
            await flowStore.checkImagesArePreloaded();

            if (animateHeight.value && oldSlideHeight.value != null && newSlideHeight.value != null)
                transition.height(
                    fadeContainer.value,
                    oldSlideHeight.value,
                    newSlideHeight.value,
                );
            else {
                fadeContainer.value.style.height = 'auto';
            }

            transition.in(fadeContainer.value as HTMLElement);

            flowStore.finishRendererTransition();
            flowStore.fireSlideLoadedEvent(newValue);
            calculatorEventService.trackEvents(flowStore.sliderService.currentSlide?.name ?? '');
        });
    }
});

const updateContainerStyles = () => {
    updateStyles();
    updateClasses();
}

const updateStyles = () => {
    const targetStyles = props.nestedSlide.slideStyles;

    if (!targetStyles) return;

    const styles = {};

    useBackgroundStyles(targetStyles, styles);

    if ((targetStyles.borderRadius ?? 0) > 0)
        Object.assign(styles, { borderRadius: `${targetStyles.borderRadius}px` });

    currentRendererStyles.value = styles;
};

const updateClasses = () => {
    const targetStyles = props.nestedSlide.slideStyles;
    if (!targetStyles) return;

    const classes: string[] = [];

    useBackgroundSlideClasses(targetStyles, classes, flowStore.flow?.getStyle() as FlowStyle);

    currentRendererClasses.value = classes;
};

const resetScrollHeight = () => {
    if (document.scrollingElement)
        document.scrollingElement.scrollTo({ top: 0, behavior: 'smooth' });
}

</script>
