import { FlowClientEventChannel, PageEventSource } from "../Stores/page-events.ts";
import { PageEvent } from "./PageEvent.ts";

export type EventHandlerFunction = {
    (eventData: GenericJson|null): Promise<void>
};

interface EventHubContract {
    name: string,
    source: PageEventSource,
    channel: string,

    trigger(eventName: string, eventData?: GenericJson): Promise<void>;
    on(eventName: string, callback: EventHandlerFunction): void;
    off(eventName: string, callback: EventHandlerFunction): void;
    once(eventName: string, callback: EventHandlerFunction): void;
}

type EventHubHandler = {
    once?: boolean,
    callback: EventHandlerFunction,
}

type EventHubHandlerStore = {
    [eventName: string]: EventHubHandler[],
}

export class EventHub implements EventHubContract {
    private readonly hubName: string;
    private readonly hubSource: PageEventSource;
    private readonly hubChannel: string;

    private handlers: EventHubHandlerStore = {};

    constructor(hubName: string, hubSource: PageEventSource, hubChannel: string, autoStart: boolean = true) {
        this.hubName = hubName;
        this.hubSource = hubSource;
        this.hubChannel = hubChannel;

        if (autoStart)
            this.listen();
    }

    public get name() {
        return this.hubName;
    }

    public get source() {
        return this.hubSource;
    }

    public get channel() {
        return this.hubChannel;
    }

    public async trigger(eventName: string, eventData?: GenericJson) {
        this.dispatchEvent(new PageEvent(eventName, eventData));
    }

    public on(eventName: string, callback: EventHandlerFunction) {
        this.subscribeToEvent(eventName, callback);
    }

    public off(eventName: string, callback: EventHandlerFunction) {
        const index = this.findHandlerIndex(eventName, callback);
        if (index !== null)
            this.handlers[eventName].splice(index, 1);
    }

    public once(eventName: string, callback: EventHandlerFunction) {
        this.subscribeToEvent(eventName, callback, true);
    }

    public start(): void {
        this.listen();
    }

    public stop(): void {
        this.stopListening();
    }

    protected async dispatchEvent(event: PageEvent): Promise<void> {
        window.postMessage(event.toJSON());
    }

    protected subscribeToEvent(eventName: string, eventHandler: EventHandlerFunction, once?: boolean): void {
        if (eventName in this.handlers) {
            if (this.findHandlerIndex(eventName, eventHandler) !== null)
                return;
        }
        else {
            this.handlers[eventName] = [];
        }

        this.handlers[eventName].push({
            callback: eventHandler,
            once: once ?? false,
        });
    }

    protected findHandlerIndex(eventName: string, callback: EventHandlerFunction): number|null {
        if (eventName in this.handlers) {
            const index = this.handlers[eventName].findIndex(handler => handler.callback === callback);
            if (index > -1)
                return index;
        }

        return null;
    }

    protected async handleEvent(event: MessageEvent): Promise<void> {
        if (event.data?.channel === FlowClientEventChannel) {
            if (event.data.source !== PageEventSource.FlowClient) {
                const eventName = event.data.event;
                if (eventName in this.handlers) {
                    const eventData = event.data.data ?? null;
                    const remove: number[] = [];
                    this.handlers[event.data.event].forEach((handler, index) => {
                        handler.callback(eventData);
                        if (handler.once)
                            remove.push(index);
                    });

                    if (remove.length)
                        this.cleanHandlers(eventName, remove);
                }
            }
        }
    }

    protected cleanHandlers(eventName: string, removeIndices: number[]): void {
        this.handlers[eventName] = this.handlers[eventName].filter((_v, i) => !removeIndices.includes(i));
    }

    protected listen(): void {
        window.addEventListener('message', this.handleEvent.bind(this));
    }

    protected stopListening(): void {
        window.removeEventListener('message', this.handleEvent.bind(this));
    }
}