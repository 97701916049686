import { defineStore } from "pinia";
import { AppConfig } from "../Config/AppConfig.ts";
import { RendererConfig } from "../Config/RendererConfig.ts";
import { useFlowStore } from "./flow.ts";


export const useConfigStore = defineStore('config', () => {
    const flowStore = useFlowStore();
    const app = new AppConfig();
    const renderer = new RendererConfig(flowStore);

    return {
        app,
        renderer,
    }
});