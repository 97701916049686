<template>
    <DevError v-if="errorStore.isDevelopment" />
    <CombinedRenderer
        :slide="currentSlide"
        :slide-template="slideTemplate"
        :initial-fade-in="initialFadeIn"
    />
</template>

<script setup lang="ts">
import {onMounted, Ref, ref, watch} from "vue";
import Theme from "@flow-builder/core/src/Styling/Theme.ts";
import {useFlowStore} from "../../Stores/flow.ts";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import DevError from "../../Errors/DevError.vue";
import { useErrorStore } from "../../Stores/errors.ts";
import CombinedRenderer from "./CombinedRenderer.vue";

interface Props {
    theme: Theme,
    initialFadeIn: boolean,
}
const props = defineProps<Props>();

const flowStore = useFlowStore();
const errorStore = useErrorStore();

const currentSlide: Ref<Slide|null> = ref(null);
const slideTemplate: Ref<Slide|null> = ref(null);

const initialize = () => {
    const el: HTMLElement = document.querySelector(':root')!;

    el.style.setProperty('--sol-color-primary', `${(props.theme as Theme).colors.primary.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-primary-light', `${(props.theme as Theme).colors.primary.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-primary-dark', `${(props.theme as Theme).colors.primary.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-secondary', `${(props.theme as Theme).colors.secondary.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-secondary-light', `${(props.theme as Theme).colors.secondary.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-secondary-dark', `${(props.theme as Theme).colors.secondary.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-white', `${(props.theme as Theme).colors.white.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-white-light', `${(props.theme as Theme).colors.white.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-white-dark', `${(props.theme as Theme).colors.white.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-off-white', `${(props.theme as Theme).colors.offWhite.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-off-white-light', `${(props.theme as Theme).colors.offWhite.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-off-white-dark', `${(props.theme as Theme).colors.offWhite.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-black', `${(props.theme as Theme).colors.black.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-black-light', `${(props.theme as Theme).colors.black.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-black-dark', `${(props.theme as Theme).colors.black.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-lighter-gray', `${(props.theme as Theme).colors.lighterGray.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-lighter-gray-light', `${(props.theme as Theme).colors.lighterGray.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-lighter-gray-dark', `${(props.theme as Theme).colors.lighterGray.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-dark-gray', `${(props.theme as Theme).colors.darkGray.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-dark-gray-light', `${(props.theme as Theme).colors.darkGray.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-dark-gray-dark', `${(props.theme as Theme).colors.darkGray.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-gray', `${(props.theme as Theme).colors.gray.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-gray-light', `${(props.theme as Theme).colors.gray.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-gray-dark', `${(props.theme as Theme).colors.gray.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-light-gray', `${(props.theme as Theme).colors.lightGray.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-light-gray-light', `${(props.theme as Theme).colors.lightGray.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-light-gray-dark', `${(props.theme as Theme).colors.lightGray.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-custom-1', `${(props.theme as Theme).colors.custom1?.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-1-light', `${(props.theme as Theme).colors.custom1?.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-1-dark', `${(props.theme as Theme).colors.custom1?.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-custom-2', `${(props.theme as Theme).colors.custom2?.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-2-light', `${(props.theme as Theme).colors.custom2?.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-2-dark', `${(props.theme as Theme).colors.custom2?.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-custom-3', `${(props.theme as Theme).colors.custom3?.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-3-light', `${(props.theme as Theme).colors.custom3?.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-3-dark', `${(props.theme as Theme).colors.custom3?.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-custom-4', `${(props.theme as Theme).colors.custom4?.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-4-light', `${(props.theme as Theme).colors.custom4?.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-4-dark', `${(props.theme as Theme).colors.custom4?.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-custom-5', `${(props.theme as Theme).colors.custom5?.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-5-light', `${(props.theme as Theme).colors.custom5?.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-5-dark', `${(props.theme as Theme).colors.custom5?.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-custom-6', `${(props.theme as Theme).colors.custom6?.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-6-light', `${(props.theme as Theme).colors.custom6?.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-6-dark', `${(props.theme as Theme).colors.custom6?.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-custom-7', `${(props.theme as Theme).colors.custom7?.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-7-light', `${(props.theme as Theme).colors.custom7?.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-7-dark', `${(props.theme as Theme).colors.custom7?.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-custom-8', `${(props.theme as Theme).colors.custom8?.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-8-light', `${(props.theme as Theme).colors.custom8?.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-8-dark', `${(props.theme as Theme).colors.custom8?.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-custom-9', `${(props.theme as Theme).colors.custom9?.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-9-light', `${(props.theme as Theme).colors.custom9?.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-9-dark', `${(props.theme as Theme).colors.custom9?.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-custom-10', `${(props.theme as Theme).colors.custom10?.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-10-light', `${(props.theme as Theme).colors.custom10?.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-10-dark', `${(props.theme as Theme).colors.custom10?.dark?.toRGBString()}`)

    el.style.setProperty('--sol-color-custom-11', `${(props.theme as Theme).colors.custom11?.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-11-light', `${(props.theme as Theme).colors.custom11?.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-custom-11-dark', `${(props.theme as Theme).colors.custom11?.dark?.toRGBString()}`)

    el.style.setProperty(`--${(props.theme as Theme).colors.gradient_light.fromName}`, `${(props.theme as Theme).colors.gradient_light.from.toRGBString()}`)
    el.style.setProperty(`--${(props.theme as Theme).colors.gradient_light.toName}`, `${(props.theme as Theme).colors.gradient_light.to.toRGBString()}`)

    el.style.setProperty(`--${(props.theme as Theme).colors.gradient_dark.fromName}`, `${(props.theme as Theme).colors.gradient_dark.from.toRGBString()}`)
    el.style.setProperty(`--${(props.theme as Theme).colors.gradient_dark.toName}`, `${(props.theme as Theme).colors.gradient_dark.to.toRGBString()}`)

    el.style.setProperty('--sol-color-transparent', `${(props.theme as Theme).colors.transparent.primary.toRGBAString()}`)

    el.style.setProperty('--sol-color-light-orange', `${(props.theme as Theme).colors.lightOrange.primary.toRGBString()}`)

    el.style.setProperty('--sol-color-green-1', `${(props.theme as Theme).colors.green1.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-green-1-light', `${(props.theme as Theme).colors.green1.light?.toRGBString()}`)
    el.style.setProperty('--sol-color-green-2', `${(props.theme as Theme).colors.green2.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-green-3', `${(props.theme as Theme).colors.green3.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-green-4', `${(props.theme as Theme).colors.green4.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-green-5', `${(props.theme as Theme).colors.green5.primary.toRGBString()}`)

    el.style.setProperty('--sol-color-red-1', `${(props.theme as Theme).colors.red1.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-red-1-light', `${(props.theme as Theme).colors.red1.light?.toRGBString()}`)

    el.style.setProperty('--sol-color-fuchsia-1', `${(props.theme as Theme).colors.fuchsia1.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-fuchsia-1-light', `${(props.theme as Theme).colors.fuchsia1.light?.toRGBString()}`)

    el.style.setProperty('--sol-color-blue-1', `${(props.theme as Theme).colors.blue1.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-blue-1-light', `${(props.theme as Theme).colors.blue1.light?.toRGBString()}`)

    el.style.setProperty('--sol-color-yellow-1', `${(props.theme as Theme).colors.yellow1.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-yellow-1-light', `${(props.theme as Theme).colors.yellow1.light?.toRGBString()}`)

    el.style.setProperty('--sol-color-purple-1', `${(props.theme as Theme).colors.purple1.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-purple-1-light', `${(props.theme as Theme).colors.purple1.light?.toRGBString()}`)

    el.style.setProperty('--sol-color-orange-1', `${(props.theme as Theme).colors.orange1.primary.toRGBString()}`)
    el.style.setProperty('--sol-color-orange-1-light', `${(props.theme as Theme).colors.orange1.light?.toRGBString()}`)

    setSlide();
}

const setSlide = () => {
    if (flowStore.sliderService.currentSlide) {
        currentSlide.value = flowStore.sliderService.currentSlide as Slide;
        slideTemplate.value = flowStore.getSlideTemplate();
    }
}

onMounted(() => initialize());

watch(() => (props.theme as Theme), () => initialize())
watch(() => flowStore.sliderService.currentSlide, () => setSlide());
</script>
