export const enum PageEventSource {
    Page = 'page',
    FlowClient = 'flow-client',
}

export const enum FlowClientEvent {
    SendHandshake = 'send-handshake',
    LoadFLow = 'load-flow',
    ClientMounted = 'client-mounted',
    FlowLoaded = 'flow-loaded',
    HandshakeComplete = 'handshake-complete',
    FirstSlideRendered = 'first-slide-rendered',
}

export const FlowClientEventChannel = 'flow-client-events';

import { defineStore } from "pinia";
import { EventHub } from "../Events/EventHub.ts";

export const usePageEventsStore = defineStore('pageEvents', () => {
    const eventHub = new EventHub(
        'pageEventHub',
        PageEventSource.FlowClient,
        FlowClientEventChannel,
    );

    return {
        on: eventHub.on.bind(eventHub),
        off: eventHub.off.bind(eventHub),
        once: eventHub.once.bind(eventHub),
        trigger: eventHub.trigger.bind(eventHub),
    }
});