<template>
    <div class="fb-flex fb-flex-col">
        <div class="fb-text-gray-500 fb-text-sm fb-font-bold fb-mb-1" v-if="label && label.trim().length > 0">
            {{ label }}
        </div>
        <div class="fb-flex fb-items-stretch fb-outline-2 fb-h-auto fb-w-full fb-rounded fb-border fb-border-gray-200 fb-bg-white"
             :class="[inputFocused && 'fb-outline',
                 instance.focusOutlineColor ? `fb-outline-sol-${instance.focusOutlineColor}` : 'fb-outline-sol-primary',
                 instance.borderColor && `fb-border-sol-${instance.borderColor}`,
             ]"
        >
            <div v-if="instance.prefix !== null"
                 class="fb-px-4 fb-py-2 fb-bg-gray-100 fb-rounded-l fb-flex fb-items-center fb-text-gray-600 fb-font-medium"
                 :style="verticalPadding"
            >
                {{ instance.prefix }}
            </div>

            <div class="fb-px-1 fb-flex fb-items-center fb-w-full"
                 :style="innerStyles"
            >
                <div v-if="instance.decoratorIcon?.content && instance.decoratorIcon.position === DecoratorIconPosition.Left"
                     class="fb-mr-3"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                         class="fb-w-6 fb-h-6"
                         :class="[instance.decoratorIcon.color ? `fb-text-sol-${instance.decoratorIcon.color}` : 'fb-text-sol-primary']"
                         v-html="instance.decoratorIcon!.content"
                    />
                </div>
                <input ref="inputEl"
                    class="fb-appearance-none fb-w-full focus:fb-outline-none focus:fb-border-none focus:fb-shadow-none fb-shadow-none fb-border-none fb-p-0"
                    :class="[textAlignment,
                        instance.prefix ? '' : 'fb-rounded-l',
                        instance.suffix ? '' : 'fb-rounded-r'
                    ]"
                    style="box-shadow: none;"
                    :disabled="instance.disabled"
                    :type="type"
                    :placeholder="placeholder"
                    :name="instance.inputName ?? ''"
                    v-model="value"
                    @focus="() => inputFocused = true"
                    @blur="() => inputFocused = false"
                    @keyup="handleKeyup"
                />
                <div v-if="instance.decoratorIcon?.content && instance.decoratorIcon.position === DecoratorIconPosition.Right"
                     class="fb-ml-3"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                         class="fb-w-6 fb-h-6"
                         :class="[instance.decoratorIcon.color ? `fb-text-sol-${instance.decoratorIcon.color}` : 'fb-text-sol-primary']"
                         v-html="instance.decoratorIcon!.content"
                    />
                </div>
            </div>
            <div v-if="instance.suffix !== null"
                 class="fb-px-4 fb-py-2 fb-bg-gray-100 fb-rounded-r fb-flex fb-items-center fb-text-gray-600 fb-font-medium"
                 :style="verticalPadding"
            >
                {{ instance.suffix }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import InputField, { DecoratorIconPosition } from "@flow-builder/core/src//Blocks/Core/Inputs/InputField.ts";
import { computed, ComputedRef, CSSProperties, onBeforeMount, onMounted, Ref, ref, watch } from "vue";
import {usePayloadStore} from "../../../Stores/payload.ts";
import {SlideUpdateEvent, useFlowStore} from "../../../Stores/flow.ts";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import {BlockAlignment} from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {usePayload} from "../../../Composables/payload.ts";
import {ErrorResponse} from "../../../Errors/SlideErrors.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import {useErrorStore} from "../../../Stores/errors.ts";
import googleService from "@flow-builder/core/src/Services/External/Google.ts";
import locationService from "@flow-builder/core/src/Services/location-service.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";

const value = ref(null);
const payloadStore = usePayloadStore();
const flowStore = useFlowStore();
const consumerStore = useConsumerStore();
const errorStore = useErrorStore();
const inputEl = ref();
const inputFocused: Ref<boolean> = ref(false);

interface Props {
    label: string,
    placeholder: string,
    type: string,
    instance: InputField,
    innerStyles: CSSProperties,
}
const props = withDefaults(defineProps<Props>(), {
    label: '',
    placeholder: '',
    type: 'text',
    innerStyles: () => ({}),
});

onBeforeMount(() => {
    const init = usePayload();
    init.setInitialBlockPayload(buildPayload());
});

const initialize = () => {
    if(!inputEl.value.hasOwnProperty("_assign"))
        inputEl.value._assign = (val: any) => value.value = val;

    const payload = payloadStore.flowPayload?.getBlockPayload(
        flowStore.sliderService.currentSlide?.id ?? '',
        props.instance.id
    );

    const initialInput = payload ? payload.payload[props.instance.backendIdentifier] : props.instance.value ?? null;

    if (initialInput)
        value.value = initialInput;
    else
        geolocate();
};

const geolocate = () => {
    if (props.instance.geolocateZipCode === true) {
        googleService.loadService().then(() => {
            //@ts-ignore
            locationService.locateApproximateLocation().then(address => {
                //@ts-ignore
                const zipCode = address.address_components.filter(component => component.types.includes("postal_code"))[0] ?? null;

                if (zipCode)
                    value.value =  zipCode.long_name;
                else
                    console.error('Failed to geolocate');
            })
        });
    }
}

const buildPayload = () => new BlockPayload(
    flowStore.sliderService.currentSlide?.id ?? '',
    {
        [props.instance.backendIdentifier]: value.value
    },
    props.instance.id
);

watch(() => value.value, () => payloadStore.flowPayload?.setBlockPayload(buildPayload()));

onMounted(() => initialize());

const handleKeyup = async (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
        const newEvent: SlideUpdateEvent = {
            type: SlideActionType.NextSlide,
            trigger: props.instance,
        }

        await flowStore.update(
            payloadStore?.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? '') ?? {},
            consumerStore.bearerToken,
            newEvent
        ).catch(e => {
            errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? '');
        });
    }
}

const verticalPadding: ComputedRef<Partial<CSSProperties>> = computed(() => ({ paddingTop: props.innerStyles?.paddingTop ?? '', paddingBottom: props.innerStyles?.paddingBottom ?? '' }));

const textAlignment = computed(() => {
    switch(props.instance.textAlignment) {
        case BlockAlignment.Left:
            return "fb-text-left";
        case BlockAlignment.Right:
            return "fb-text-right";
        case BlockAlignment.Center:
        default:
            return "fb-text-center";
    }
})

</script>