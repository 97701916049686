import {useFlowStore} from "../Stores/flow.ts";
import { useConfigStore } from "../Stores/config.ts";

export interface Transition {
    in(el: HTMLElement): Promise<any>;
    out(el: HTMLElement): Promise<any>;
    height(element: HTMLElement, initialHeight: number, finalHeight: number): Promise<void>;
}

export class FadeTransitionService implements Transition {

    protected fadeDuration: number;
    protected heightDuration: number;
    protected heightAnimationInterval = 1;

    constructor() {
        const config = useConfigStore();
        this.fadeDuration = config.renderer.getFadeDuration();
        this.heightDuration = config.renderer.getHeightDuration();
    }

    in(el: HTMLElement): Promise<void> {
        const startTime = Date.now();
        const flowStore = useFlowStore();
        if(flowStore.showLoading) {
            return new Promise(resolve => {
                el.style.opacity = '1';
                resolve();
            })
        }

        return new Promise(resolve => {
            let opacity = 0;
            el.style.opacity = opacity.toString();

            const fade = () => {
                const currentDuration = Date.now() - startTime;
                opacity = (currentDuration/this.fadeDuration);
                if (opacity >= 1) {
                    el.style.opacity = '1';
                    resolve();
                } else {
                    el.style.opacity = opacity.toFixed(2);

                    requestAnimationFrame(fade);
                }
            };

            fade();
        });
    }

   out(el: HTMLElement): Promise<void> {
       const startTime = Date.now();
       const flowStore = useFlowStore();
       if(flowStore.showLoading) {
           return new Promise(resolve => {
               el.style.opacity = (1).toString();
               resolve();
           })
       }

       return new Promise(resolve => {
           let opacity = 1;
           el.style.opacity = opacity.toString();

           const fade = () => {
               const currentDuration = Date.now() - startTime;
               opacity = 1 - (currentDuration / this.fadeDuration);

               if(opacity <= 0) {
                   el.style.opacity = '0';
                   resolve();
               } else {
                   el.style.opacity = opacity.toFixed(2);
                   requestAnimationFrame(fade);
               }
           };

           fade();
       });
    }

    /**
     * Animate container height change
     */
    async height(container: HTMLElement, initialHeight: number, finalHeight: number): Promise<void> {
        if (isNaN(initialHeight) || isNaN(finalHeight)) {
            container.style.height = 'auto';
            return;
        }

        const animationDuration = this.heightDuration;
        const animationInterval = this.heightAnimationInterval;
        const heightDelta = finalHeight - initialHeight;

        const setHeight = (newHeight: number|string, pixels = true) => {
            const suffix = pixels ? 'px' : '';
            container.style.height = `${newHeight}${suffix}`;
        }

        let currentHeight = initialHeight;
        let start = Date.now();

        const animateHeight = setInterval(() => {
            if ((heightDelta >= 0 && currentHeight >= finalHeight)
                || (heightDelta < 0 && currentHeight <= finalHeight))
            {
                setHeight('', false);
                clearInterval(animateHeight);
            }
            else {
                const elapsed = Date.now() - start;
                const elapsedPercentage = elapsed/animationDuration;
                currentHeight = initialHeight + (heightDelta * elapsedPercentage);

                setHeight(currentHeight);
            }
        }, animationInterval);
    }
}