<template>
    <Renderer :theme="theme" :initial-fade-in="initialFadeIn" class="fb-flex-1" />
</template>

<script setup lang="ts">
import Renderer from "./Renderer/WebRenderer/Renderer.vue";
import Flow from "@flow-builder/core/src/Flows/Flow.ts";
import {defaultTheme} from "@flow-builder/core/src/Styling/Theme.ts";
import {useFlowStore} from "./Stores/flow.ts";
import {Google} from "@flow-builder/core/src/Services/External/Google.ts";
import {usePayloadStore} from "./Stores/payload.ts";
import FlowPayload from "./Payload/FlowPayload.ts";
import {ClientFlowResponse, getFlow} from "./Composables/getFlowService.ts";
import { onMounted, Ref, ref } from "vue";
import {useConsumerStore} from "./Stores/consumer.ts";
import { EngineInputs, EngineOutputs, useEngineStore } from "./Stores/engines.ts";
import DelayedScriptLoader from "./Services/DelayedScriptLoader.ts";
import SessionStorageService from "./Services/SessionStorageService.ts";
import { FlowClientEvent, usePageEventsStore } from "./Stores/page-events.ts";
import { useConfigStore } from "./Stores/config.ts";

declare global {
    interface Window {
        flowRevision?: string,
        calculatorLoaded?: boolean,
        calculatorRendered?: boolean,
        VITE_COMMIT_HASH: string,
    }
    type GenericJson = {
        [key: string]: any,
    }
}

interface Props {
    flow?: string,
    revision?: string,
    initial?: object,
    workingRevision?: boolean,
    loadFlow?: boolean,
    sendHandshake?: boolean,
    initialFadeIn?: boolean,
}
const props = withDefaults(defineProps<Props>(), {
    loadFlow: true,
    sendHandshake: true,
    initialFadeIn: false,
});

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const engineStore = useEngineStore();
const sessionStorageService = new SessionStorageService();
const pageEventStore = usePageEventsStore();

const flowInstance = new Flow();
const engines: Ref<string[]> = ref([]);
const theme = defaultTheme;

const flowItem: Ref<ClientFlowResponse|null> = ref(null);
const isDeletedRevision: Ref<boolean> = ref(false);
const initialized: Ref<boolean> = ref(false);

flowStore.showLoading = false;

const getFlowRevision = async() => {
    if (flowItem.value)
        return;

    const flowId = getFlowId();
    const revisionId = getRevisionId();
    const workingBranch = getWorkingBranchFlag();

    const flowResponse = await getFlow(flowId, revisionId, workingBranch);
    if (!flowResponse)
        console.warn(`Failed to fetch revision: ${revisionId}`);

    flowItem.value = flowResponse;
    flowStore.flowKey = flowItem.value?.flowKey || null;
    flowInstance.update(flowItem.value?.flowJson);

    engines.value = flowInstance.getEngines().map((item) => item.name);

    flowStore.flow = flowInstance;
    useConfigStore();
    payloadStore.flowPayload = new FlowPayload();
    isDeletedRevision.value = flowResponse?.deletedFlow ?? false;

    pageEventStore.trigger(FlowClientEvent.FlowLoaded);
}

const initializeClient = async(eventData?: GenericJson|null) => {
    if (initialized.value)
        return;

    if (!flowItem.value)
        await getFlowRevision().catch(e => console.error(e));

    if (shouldResumeExistingFlow()) {
        sessionStorageService.initializeFromSessionStorage();
    }
    else {
        window.calculatorRendered = true;
        const industryService = flowStore.flow?.getDefaultService().length ? flowStore.flow?.getDefaultService() : undefined;
        const initialData = eventData ?? props.initial ?? null;

        const handshakeResponse = await flowStore.flowApiService.handshake(flowStore.flowKey as string, engines.value, industryService, initialData, isDeletedRevision.value)
            .catch(e => console.error(e));

        if (handshakeResponse?.data?.data?.bearer_token) {
            initialized.value = true;
            consumerStore.bearerToken = handshakeResponse.data?.data?.bearer_token;
            consumerStore.tokenExpiresAt = handshakeResponse.data?.data?.expires_at ?? null;
            engineStore.engines = handshakeResponse?.data?.data?.engines ?? [];

            const initialEnginesInputs = handshakeResponse.data.data.consumer?.inputs?.new ?? {};
            const initialEnginesOutputs = handshakeResponse.data.data.consumer?.outputs ?? null;

            updateEngineStoreWithInitialData(initialEnginesInputs, initialEnginesOutputs);

            flowStore.sliderService.initializeService(true, initialEnginesInputs);
            openEnginesWebsocket();
            updateSessionStorage();

            flowStore.showLoading = false;
            pageEventStore.off(FlowClientEvent.SendHandshake, initializeClient);
        }
    }

    flowStore.initializeWatchDog();

    pageEventStore.trigger(FlowClientEvent.HandshakeComplete);
}

const updateEngineStoreWithInitialData = (inputs: EngineInputs|null, outputs: EngineOutputs|null) => {
    engineStore.service.commitInitialDataToStore(inputs, outputs);
}

const updateSessionStorage = () => {
    sessionStorageService.consumerToken = consumerStore.bearerToken;
    sessionStorageService.engines = engineStore.engines;
    sessionStorageService.engineInputs = engineStore.inputs;
    sessionStorageService.engineOutputs = engineStore.outputs;
}

const openEnginesWebsocket = () => {
    engineStore.service.listenForResults(consumerStore.bearerToken);
}

// TODO: extend resume-able flows with localStorage / new service
const shouldResumeExistingFlow = (): boolean => {
    return !!(flowStore.flow?.shouldKeepState() && sessionStorageService.consumerToken);
}

const getRevisionId = (): string|null => {
    return (import.meta.env.VITE_ENVIRONMENT === 'local' && import.meta.env.VITE_TEST_REVISION_ID)
        ? import.meta.env.VITE_TEST_REVISION_ID
        : window.flowRevision
        ?? props.revision
        ?? null;
}

const getFlowId = (): string|null => {
    return (import.meta.env.VITE_ENVIRONMENT === 'local' && import.meta.env.VITE_TEST_FLOW_ID)
        ? import.meta.env.VITE_TEST_FLOW_ID
        : props.flow
        ?? null;
}

const getWorkingBranchFlag = (): boolean => {
    return ((import.meta.env.VITE_ENVIRONMENT === 'local' && import.meta.env.VITE_TEST_WORKING_BRANCH)
        || props.workingRevision);
}

const setCommitHash = (): void => {
    window.VITE_COMMIT_HASH = import.meta.env.VITE_COMMIT_HASH ?? '';
}

onMounted(() => {
    pageEventStore.trigger(FlowClientEvent.ClientMounted);
    window.calculatorLoaded = true;
});

setCommitHash();
// Unless the containing website wants to delay starting the calculator, initialize immediately
if (props.loadFlow) {
    await getFlowRevision();
    if (props.sendHandshake) {
        initializeClient();
    }
    else {
        pageEventStore.on(FlowClientEvent.SendHandshake, initializeClient);
    }
}
else {
    pageEventStore.on(FlowClientEvent.LoadFLow, getFlowRevision)
}

new Google().loadService();

DelayedScriptLoader.loadAnalyticsScript(flowStore.flow?.getGtmId() ?? null, flowStore.flow?.getGaId() ?? null);
</script>
