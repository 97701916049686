import { defineStore } from "pinia";
import { useFlowStore } from "./flow.ts";
import { ref, Ref } from "vue";
import { FlowGlobalVariableType } from "@flow-builder/core/src/LogicOperations/GlobalVariables/GlobalVariable.ts";
import { useErrorStore } from "./errors.ts";
import { FlowClientGlobalVariable, FlowGlobalVariableValue } from "@flow-builder/core/src/LogicOperations/GlobalVariables/GlobalVariable.ts";
import { FlowClientVariableCollection } from "@flow-builder/core/src/LogicOperations/GlobalVariables/GlobalVariable.ts";

export const useLogicStore = defineStore('logic', () => {
    const flowStore = useFlowStore();
    const errorStore = useErrorStore();

    const globalVariables: Ref<FlowClientGlobalVariable[]> = ref([]);
    const initialised: Ref<boolean> = ref(false);

    const initialize = () => {
        if (initialised.value)
            return;

        globalVariables.value = (flowStore.flow?.getGlobalVariables() ?? [])
            .map(variable => ({
                ...variable,
                value: undefined,
            }));
        initialised.value = true;
    }

    const getVariable = (variableKey: string): FlowClientGlobalVariable|null => {
        const target = globalVariables.value.find(variable => variable.name === variableKey);

        return target
            ? JSON.parse(JSON.stringify(target))
            : null;
    }

    const getVariableType = (variableKey: string): FlowGlobalVariableType|null => {
        const target = globalVariables.value.find(variable => variable.name === variableKey);

        return target?.type as FlowGlobalVariableType
            ?? null;
    }

    const getVariableValue = (variableKey: string, formatForDisplay = false): FlowGlobalVariableValue|null => {
        const target = globalVariables.value.find(variable => variable.name === variableKey);
        if (!target)
            return null;

        return formatForDisplay
            ? formatValue(target)
            : target.value ?? target.defaultValue ?? null;
    }

    const formatValue = (variable: FlowClientGlobalVariable) => {
        if (variable.type === FlowGlobalVariableType.NUMBER) {
            return Intl.NumberFormat("en-US").format((variable.value ?? variable.defaultValue ?? 0));
        }

        return variable.value ?? variable.defaultValue ?? null;
    }

    const getVariableCollection = (): FlowClientVariableCollection => {
        return globalVariables.value.reduce((output, variable) =>
            ({ ...output, [variable.name]: variable.value ?? variable.defaultValue ?? null }), {});
    }

    const setVariableValue = (variableKey: string, newValue: FlowGlobalVariableValue|null): void => {
        const target = globalVariables.value.find(variable => variable.name === variableKey);
        if (target) {
            const validated = validateType(target, newValue);
            if (validated !== undefined) {
                target.value = validated;
            }
        }
        else
            handleError(`Attempted to set non-existent variable: '${variableKey}'`);
    }

    const validateType = (variable: FlowClientGlobalVariable, value: any): FlowGlobalVariableValue|undefined => {
        if (value !== undefined) {
            // An explicit null may be useful for resetting a variable
            if (value === null) {
                return value;
            }
            else if (variable.type === FlowGlobalVariableType.BOOLEAN) {
                if ([1, true].includes(value) || /^true/i.test(value))
                    return true;
                if ([0, false].includes(value) || /^false/i.test(value))
                    return false;
            }
            else if (variable.type === FlowGlobalVariableType.NUMBER) {
                const valid = Number(value);
                if (!isNaN(valid))
                    return valid;
            }
            else if (variable.type === FlowGlobalVariableType.TEXT) {
                const valid = `${value}`.trim();
                if (valid)
                    return valid;
            }
            else
                handleError(`Bad Type defined on variable '${variable.name}': ${variable.type}`);
        }
        else
            handleError(`GlobalVariable TypeError: attempted to assign value '${value}' to variable ${variable.name}.\nExpected type: ${variable.type}`);

        return undefined;
    }

    const handleError = (errorMessage: string) => {
        if (errorStore.isDevelopment)
            errorStore.showDevError(errorMessage);
        else
            console.warn(errorMessage);
    }

    return {
        initialize,
        getVariable,
        getVariableType,
        getVariableValue,
        getVariableCollection,
        setVariableValue,
    }
});