<template>
    <div v-if="errors.length">
        <div
            v-html="errors.map(err => err.messages.join('<br>')).join('<br>')"
            class="fb-bg-red-100 fb-border fb-border-red-400 fb-text-red-700 fb-px-4 fb-py-3 fb-rounded fb-relative fb-m-5"
        />
    </div>
</template>

<script setup lang="ts">

import {useErrorStore} from "../../../Stores/errors.ts";
import {useFlowStore} from "../../../Stores/flow.ts";
import { ref, Ref, watch } from "vue";
import SlideError from "@flow-builder/core/src/Blocks/Core/Errors/SlideError.ts";
import Error from "../../../Errors/Error";

interface Props {
    instance: SlideError
}
const props = defineProps<Props>();

const errorStore = useErrorStore();
const flowStore = useFlowStore();

const errors: Ref<Error[]> = ref([]);

const autoCloseError = () => {
    if (props.instance.autoCloseTimer! > 0) {
        setTimeout(() => {
            errors.value = [];
        }, props.instance.autoCloseTimer as number);
    }
}
watch(errorStore.slideErrors, () => {
    errors.value = errorStore.slideErrors.getSlideErrors(flowStore.sliderService.currentSlide?.id ?? '');
    autoCloseError();
});

</script>