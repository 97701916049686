import { defaultDesktopFadeDuration, defaultMobileFadeDuration, FlowStyle } from "@flow-builder/core/src/Flows/Flow.ts";
import { useFlowStore } from "../Stores/flow.ts";

enum MediaWidth {
    Mobile = 0,
    Desktop = 1,
}
export const MediaWidthType = MediaWidth;

export class RendererConfig {
    private readonly screenRenderer: FlowStyle;
    private readonly screenWidth: MediaWidth;

    private readonly mobileFadeDuration: number = 325;
    private readonly desktopFadeDuration: number = 325;


    private readonly defaultHeightAnimationDuration = 150;
    private readonly usesHeightAnimation: boolean;

    constructor(flowStore: ReturnType<typeof useFlowStore>) {
        this.screenRenderer = flowStore.flow?.getStyle() ?? FlowStyle.Fullscreen;
        this.screenWidth = window.innerWidth < 768
            ? MediaWidth.Mobile
            : MediaWidth.Desktop;

        this.mobileFadeDuration = flowStore.flow?.getMobileFadeDuration() ?? defaultMobileFadeDuration;
        this.desktopFadeDuration = flowStore.flow?.getDesktopFadeDuration() ?? defaultDesktopFadeDuration;
        this.usesHeightAnimation = flowStore.flow?.getUsesHeightAnimation() ?? false;
    }

    public mediaWidth(): MediaWidth {
        return this.screenWidth;
    }

    public isMobile(): boolean {
        return this.screenWidth === MediaWidth.Mobile;
    }

    public rendererType(): FlowStyle {
        return this.screenRenderer;
    }

    public isFullscreen(): boolean {
        return this.screenRenderer === FlowStyle.Fullscreen;
    }

    public isSlider(): boolean {
        return this.screenRenderer === FlowStyle.Slider;
    }

    public getFadeDuration(): number {
        return this.isMobile()
            ? this.mobileFadeDuration
            : this.desktopFadeDuration;
    }

    public getHeightDuration(): number {
        return Math.min(this.defaultHeightAnimationDuration, this.getFadeDuration());
    }

    public useHeightAnimation(): boolean {
        return this.usesHeightAnimation;
    }
}