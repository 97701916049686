enum Environment {
    Production = 'production',
    Development = 'development',
    Local = 'local',
}

export const AppEnvironment = Environment;

export class AppConfig {
    private readonly appEnvironment: Environment;

    constructor() {
        this.appEnvironment = Object.entries(Environment).includes(import.meta.env.VITE_ENVIRONMENT)
            ? import.meta.env.VITE_ENVIRONMENT
            : Environment.Production;
    }

    public isProduction(): boolean {
        return this.appEnvironment === Environment.Production;
    }

    public environment(): Environment {
        return this.appEnvironment;
    }
}