<template>
    <Suspense>
        <Client
            id="flow-client"
            :flow="flow"
            :initial="formattedInitialData"
            :revision="revision"
            :working-revision="castBoolean(workingRevision)"
            :load-flow="castBoolean(loadFlow)"
            :send-handshake="castBoolean(sendHandshake)"
            :initial-fade-in="castBoolean(initialFadeIn)"
        />
    </Suspense>
</template>

<script setup lang="ts">
import Client from "./Client.vue";
import { computed } from "vue";
import { useCompatibilityStore } from "./Stores/compatibility.ts";

interface Props {
    flow?: string,
    revision?: string,
    initial?: string,
    workingRevision?: string|boolean,
    previewMode?: string|boolean,
    loadFlow?: string|boolean,
    sendHandshake?: string|boolean,
    initialFadeIn?: string|boolean,
}
const props = withDefaults(defineProps<Props>(), {
    loadFlow: true,
    sendHandshake: true,
    previewMode: false,
    workingRevision: false,
    initialFadeIn: false,
});

const castBoolean = (htmlValue: string|boolean|number) => !['false', false, 0, '0'].includes(htmlValue);

// load any polyfill/workaround needed for outdated browsers
const compatibilityStore = useCompatibilityStore();
compatibilityStore.initialize();
if (compatibilityStore.hasCompatibilityIssues)
    compatibilityStore.compatibilityMessages.forEach(msg => console.warn(msg));

window.previewMode = castBoolean(props.previewMode);

const formattedInitialData = computed(() => props.initial != null ? JSON.parse(props.initial as string) : null);
</script>